/*------------------------------------*\
    # LOCAL GENERAL SETTINGS
\*------------------------------------*/

/*------------------------------------*\
    ## COLOURS
\*------------------------------------*/

/**
 * All colours should be defined here; colour values should not be set in
 * partials.
 *
 * All colour variables should be prefixed with `$color-`. For example,
 * `$color-red`. Note the US spelling; this has been chosen to reduce
 * typographic errors in the `color` property.
 *
 * Colour variables can either be descriptive (eg `$color-red`) or
 * functional (eg `$color-text`).
 *
 * Tints or shades should use the parent variables with a `--` suffix. For
 * examples, `$color-red--light`.
 **/

/* Mollini specific colours used throughout the theme */
$color-mollini-black: #000;;
$color-mollini-lightpink: #faeff3;
$color-mollini-grey: #666666;
$color-mollini-accent: #000;


$color-navigation-background: #fff;
$color-navigation-border: #fff;

$color-site-footer-subscribe: #fff;
$color-site-footer-subscribe-text: #000;
$color-swatch-border: $color-mollini-black;

/* General page settings */
$color-background: #fcfcfc;
$color-background-content: #fff;
$color-links: inherit;
$color-text: #333;

$color-sale: #CC0000;
$color-header-background: #fff;
$color-menu-items-border: #D3D3D3;
$return-button-colour: $color-mollini-black;

$color-light-hover: #0000001a;
$address-validation-hover: $color-light-hover;

/* Footer settings */
$color-background-footer-sitemap-contact: #fff;

/*------------------------------------*\
    ## FONTS
\*------------------------------------*/

/**
 * All fonts should be defined here.
 *
 * All font-family variables should be prefixed with `$font-family-`.
 **/

$font-family-base: serif;
$font-size-icon-navigation-bar: 41px;

$font-family-base: 'Helvetica Neue Regular', Arial, serif;
$font-family-base-functional: 'Helvetica Neue Regular', Arial, sans-serif;
$font-family-headings: 'Helvetica Neue Regular', Arial, sans-serif;

/* in Mollini we need to overide the components icon font to use our own set. */
$font-family-components-header: 'munro-icons';
/* we also need to override the icon codes */
$wishlist-icon: '\e903';
$minicart-icon: '\e902';

/*------------------------------------*\
    ## LAYOUT
\*------------------------------------*/

/**
 * Any Layout related overrides can be defined in here
 **/

$stockist-icon-left-offset: -25px;

/* Stock Remaining Message */
.product__stock-message {
  color: $color-mollini-accent;
}
