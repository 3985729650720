/*------------------------------------*\
# COMPONENT OVERRIDES
\*------------------------------------*/

/**
 * Add local component overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

// The icon font used for Mountfords, Mollini and Cinori is 'bigger' than the Material Icons font, so where the font
// is being used or its icons, the font size needs to be reduced.
.site-header__mobile-top .site-header__mobile-links li a:before {
  font-size: 21px !important;
  line-height: 1.3em !important;
}

// PDP Recently Viewed Override
@media (max-width: 960px) {
  .recently-viewed-pdp-container {
    display: none !important;
  }
}

/* Sort By Options Fixes */
@media (max-width: 959px) {
  .catalog-category-view .toolbar {
    padding: 0 10px;
  }
  .toolbar__sorter {
    width: 65%;
  }
  .toggle-refine-filters-container {
    width: 35%;
  }
  .toggle-refine-filters-container .toggle-refine-filters,
  .toolbar__sorter .sorter-container select {
    font-size: 14px;
  }
  .toolbar__sorter .sorter-container select {
    padding: 0 27px 0 13px;
  }

  // Enable 2-up PLP - Copied from Styletread implementation
  .product-list__item {
    width: 50%;

    .product-tile {
      padding-left: 0 !important;

      .product-tile__actions--secondary {
        display: none;
      }
    }
  }
}

.minicart {
  .out-of-stock {
    font-size: 11px;
    text-transform: none;
    text-align: left;
    font-weight: 600;
  }
}

.checkout-cart-index {
  .messages {
    .message-error {
      font-size: 11px;
    }
  }

  .cart-table__message {
    &.error {
      font-size: 11px;
    }
  }
}

/* Quick View Changes */
.quickview__view-full-details-container .quickview__view-full-details-button {
  color: #000;
  border: 1px solid #000;
  background-color: initial;
}

/* MMI-557 */
@media (max-width: 960px) {
  .category-description {
    display: none;
  }
}
/* MMI-626 */
.recently-viewed-pdp-container {
  background-color: #fff;
}

/* MMI-986 */
.quickview .badge-container .badge {
  color: $color-sale;
  border: 1px solid $color-sale;
  background-color: initial;
  font-size: 12px;
}
/* CSS removed from Sitewide banner static block */

/* Temp fix for layout caching issue */
.cms-page-view .page-layout--two-col .page-layout__item--primary,
.cms-home .page-layout--two-col .page-layout__item--primary {
  width: 100% !important;
}
/* End temp fix */

/* Fix for banner sale issue. */
@media (max-width: 960px) {
  .catalog-product-view .product-info .sale-banner-exists {
    position: relative;
    top: 0 !important;
  }
  .catalog-product-view .product-img-inc-banner {
    margin-top: -100px;
  }
}
@media (max-width: 960px) {
  .catalogsearch-result-index .main-content h1.fh_search_term {
    margin-bottom: 0 !important;
    margin-top: -30px;
  }
}
/* Fix for BIS button to hide initially */
.product-notify-me-wrapper .hidden-notify {
  display: none;
}

/* Fix for sale badge */
.wrapper--gutter {
  position: relative;
}
@media (max-width: 750px) {
  .product-info .badge-container {
    margin-left: 0px !important;
  }
}
@media (max-width: 960px) {
  .product-info .badge-container {
    top: 10px !important;
  }
}

.cart-summary-right-column #block-discount,
#checkout .one-page-checkout__summary
{
  background-color: #E2DFD7;
}

/* Fix for MMI-3056 Logo is not visible on screen size 960px */
@media (width: 960px) {
  .site-header__banner .siteHeaderBannerInnerContent > * {
    display: block;
  }
}