.product-info .badge-container .badge {
  transform: initial;
  background-color: initial;
  color: $color-sale;
  top: 34px;
  left: 0;
  width: 88px;
  font-size: 14px;
}

.product-info .badge-container .badge:before,
.product-info .badge-container .badge:after {
  border: 0;
}

.product-info .badge-container {
  border: 1px solid $color-sale;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  z-index: 1;
  margin-left: 120px;
}

/* MMI-986 */
@media (max-width: 960px) {
  .product-info .badge-container {
    width: 80px;
    height: 80px;
  }
  .product-info .badge-container .badge {
    font-size: 14px;
    width: 80px;
    text-align: center;
    top: 29px;
  }
}

@media (max-width: 640px) {
  .product-info .badge-container {
    margin-left: 15px;
  }
  .product-info .badge-container .badge {
    top: 29px;
  }
}

.recently-viewed-pdp-container li.product-item {
  margin-right: 5px;
}

/* MMI-1759 PDP Hero image */
.fotorama__wrap .fotorama__stage__frame .fotorama__img {
    max-height: 94.20%;
    max-width: 79.5%;
}