.siteHeaderBannerContent .siteHeaderBannerInnerContent {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.slideout-menu {
  padding-top: 55px;
}

.siteHeaderBannerContent .siteHeaderBannerInnerContent #js-aligent_fredhopper-autocomplete-wrapper {
  flex: 0 0 200px;
  width: initial;
  padding: 0;
  margin: 0;
}

.siteHeaderBannerContent {
  padding: 25px 0.75rem 0.75rem 15px;
}

#js-aligent_fredhopper-autocomplete-wrapper .minisearch__control--submit {
  right: initial;
  left: 0;
  background: url("/media/wysiwyg/Mollini/icons/search-icon.png") no-repeat;
  display: block;
  width: 21px;
  height: 22px;
  background-size: contain;
  outline: 0;
}

#js-aligent_fredhopper-autocomplete-search {
  border: 0;
  border-bottom: 1px solid #000;
  outline: 0;
  padding: 0 0 0 5px;
  margin-left: 10px;
  height: 25px;
  width: 150px;
  margin-top: 6px;
  font-size: 14px;
}

.minisearch__control--submit:after {
  content: "";
  display: none;
}

#js-aligent_fredhopper-autocomplete-search::placeholder {
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 12px;
}

.navigation-bar__group--account .wishlist a:before,
.navigation-bar__group--account .minicart a:before {
  content: "";
  display: none;
}

.navigation-bar__group--account li.wishlist a {
  background: url("/media/wysiwyg/Mollini/icons/wishlist-icon.png") no-repeat;
  display: block;
  width: 24px;
  height: 22px;
  background-size: contain;
}

.navigation-bar__group--account li.wishlist .nav-wishlist-text,
.navigation-bar__group--account li.minicart .minicart__label {
  display: none !important;
}

.navigation-bar__group--account li.minicart > a {
  background: url("/media/wysiwyg/Mollini/icons/bag-icon.png") no-repeat;
  display: block;
  width: 20px;
  height: 22px;
  background-size: contain;
}

.navigation-bar__group--account li.authorization-link a:before {
  content: "";
  display: none;
}
.navigation-bar__group--account li.authorization-link {
  padding-left: 10px;
}

.navigation-bar__group--account li.authorization-link a {
  background: url("/media/wysiwyg/Mollini/icons/account-icon.png") no-repeat;
  display: block;
  width: 23px;
  height: 22px;
  background-size: contain;
}

.navigation-bar__group--account .authorization-link a[href*="logout"] {
  display: none !important;
}

.navigation-bar__group--account li.customer-welcome a {
  background: url("/media/wysiwyg/Mollini/icons/account-icon.png") no-repeat;
  display: block;
  width: 23px;
  height: 22px;
  background-size: contain;
}

.navigation-bar__group--account {
  text-align: right;
}

.navigation-bar__group--account > li {
  width: initial;
  margin-left: 10px;
}

.navigation-bar__group--account .minicart,
.navigation-bar__group--account .wishlist {
  padding-left: 10px;
}

/* MMI-984 - Mobile */
.site-header__mobile-top .site-header__mobile-links li.minicart a:before,
.site-header__mobile-top .site-header__mobile-links li.wishlist a:before,
.site-header__mobile-top .mobile-header-search-toggle-container .mobile-header-search-toggle:before,
.navigation-bar-mobile__toggle:before,
.navigation-bar-mobile__toggle.open:before {
  content: "";
  display: none;
}

.site-header__mobile-top .site-header__mobile-links li.minicart a {
  background: url("/media/wysiwyg/Mollini/icons/bag-icon.png") no-repeat;
  display: block;
  width: 20px;
  height: 22px;
  background-size: contain;
}

.site-header__mobile-top .site-header__mobile-links li.wishlist a {
  background: url("/media/wysiwyg/Mollini/icons/wishlist-icon.png") no-repeat;
  display: block;
  width: 24px;
  height: 22px;
  background-size: contain;
}

.site-header__mobile-top .mobile-header-search-toggle-container .mobile-header-search-toggle,
.navigation-bar-scrolled .search-suggest-icon-wrapper .search-suggest-icon {
  background: url("/media/wysiwyg/Mollini/icons/search-icon.png") no-repeat;
  display: block;
  width: 21px;
  height: 22px;
  background-size: contain;
  outline: 0;
}

.navigation-bar-scrolled .search-suggest-icon-wrapper .search-suggest-icon:before {
  display: none;
  content: "";
}

.navigation-bar-mobile__toggle {
  background: url("/media/wysiwyg/Mollini/icons/menu-icon.png") no-repeat;
  display: block;
  width: 30px;
  height: 22px;
  background-size: contain;
}

.navigation-bar-mobile__toggle.open {
  background: url("/media/wysiwyg/Mollini/icons/close-icon.png") no-repeat;
  display: block;
  width: 22px;
  height: 22px;
  background-size: contain;
}

.site-header__strip .topInfoBarContainer ul.header.links {
  display: none;
}

.site-header__strip .topInfoBarOuterContainer .topInfoBar {
  width: 100%;
  text-align: center;
}

.site-header__strip .topInfoBarContainer .topInfoBar li a:before {
  display: none;
}

.site-header__strip .topInfoBarContainer .topInfoBar li + li {
  padding: 0;
}

.site-header__strip .topInfoBarContainer .topInfoBar li:first-of-type {
  padding-right: 60px;
}

.site-header__strip .topInfoBarContainer .topInfoBar li:last-of-type {
  padding-left: 60px;
}

.site-header__strip .topInfoBarContainer .topInfoBar li:nth-of-type(2) {
  padding: 0 60px;
  border-left: 1px solid #8e8e8e;
  border-right: 1px solid #8e8e8e;
}

.site-header__strip {
  border-bottom: 1px solid #8e8e8e;
}

.site-header__strip .topInfoBarContainer {
  text-transform: capitalize;
}

.minicart__summary .minicart__count {
  right: -7px;
  top: -5px;
  font-size: 10px;
  width: 18px;
  height: 18px;
}

.minicart .minicart__details {
  right: 0;
}

.minicart .minicart__details .up-arrow {
  right: 4px;
}

.navigation-bar__group--account .wishlist .counter {
  right: -5px;
  top: -2px;
  font-size: 10px;
  width: 18px;
  height: 18px;
}

.site-header__logo-container {
  position: absolute;
  top: 61px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

@media (min-width: 960px) {
  .site-header__logo-container {
    width: initial;
  }
}

.minicart .minicart__details .block-content .actions .primary .action.primary {
  width: 100%;
}

.minicart .minicart__details .block-content .actions .secondary .viewcart {
  text-align: center;
  border: 1px solid #333;
  margin: 0;
  padding: 10px;
  margin-top: -10px;
}

.minicart .minicart__details .block-content .actions .secondary .viewcart span {
  font-size: 15px;
}

.minicart-items .product-item .product-item-details {
  border-bottom: 1px solid #8e8e8e;
  padding-bottom: 5px;
}

.minicart .minicart__details .block-content .subtotal {
  text-align: initial;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  border-top: 1px solid #8e8e8e;
  border-bottom: 1px solid #8e8e8e;
  padding: 10px 5px;
}

.minicart .minicart__details .block-content .subtotal .label,
.minicart .minicart__details .block-content .subtotal .price-container {
  flex: 0 0 50%;
  font-size: 16px;
  text-transform: uppercase;
}

.minicart .minicart__details .block-content .subtotal .label {
  text-align: left;
  margin-top: 0 !important;
}

.minicart .minicart__details .block-content .subtotal .price-container {
  text-align: right;
  padding: 0;
}

.minicart-items .product-item .product-item-details .product-item-pricing .price-container {
  text-align: right;
}

.minicart-items .product-item .product-item-details .product-item-pricing .price-container > * {
  color: #000;
  font-size: 14px;
}

.product-item-details .details-qty,
.product-item-details .product.options.list .label {
  text-transform: capitalize;
}

.product-item-details .product.options.list .label,
.product-item-details .product.options.list .values,
.minicart-items .product-item .product-item-details .details-qty label,
.minicart-items .product-item .product-item-details .details-qty input {
  width: auto;
  text-align: left;
  font-size: 14px;
}

.minicart-items .product-item .product-item-details .details-qty input,
.product-item-details .product.options.list .values {
  margin-left: 5px;
}

.product-item-details .product.options.list {
  padding: 0;
}

.minicart-items .product-item .product-item-details .details-qty {
  height: 21px;
}

.product-item-details .product-item-pricing {
  margin-top: 10px;
}

.navigation-bar-scrolled .minicart__summary .minicart__count {
  right: -5px;
  top: 0;
}

.navigation-bar-scrolled .navigation-bar__group--account .wishlist .counter {
  right: -3px;
  top: 0;
}

.navigation-bar-scrolled .header-icons-wrapper {
  height: 48px;
  display: flex !important;
  align-items: center;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.minicart {
  border: 0;
  padding-left: 0;
  margin-left: 10px;
}

.navigation-bar-scrolled .fh-search-suggest-wrapper {
  margin-top: 0;
  margin-left: 0;
  width: auto;
  right: 180px;
  padding: 0 0 10px 0;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group > li {
  margin: 0 10px;
}

.site-header__mobile-top {
  padding: 15px 10px;
}

.site-header__mobile-top .site-header__mobile-links li + li {
  padding-left: 10px;
}

.site-header__mobile-top .minisearch {
  position: absolute;
  background: #fff;
  top: 55px;
  width: 100%;
  margin-left: -10px;
  max-height: 60px;
}

.site-header__mobile-top .minisearch .minisearch__control--text {
  margin-top: 25px;
  height: 58px;
  border: 0;
  border-bottom: 1px solid #c9c9c9;
  outline: 0;
  padding: 10px 20px;
}

.site-header__mobile-top .minisearch .search-close {
  top: 44px;
}

.site-header__mobile-links .wishlist .counter {
  top: -5px;
  right: -7px;
}

.customer-welcome .customer-name:before,
.authorization-link a[href*="login"]:before {
  content: "";
  display: none;
}

.mobile-navigation-container .authorization-link {
  height: 52px;
  background-color: #f4f4f4;
}

.mobile-navigation-container .authorization-link a {
  padding: 0 20px;
  position: relative;
  top: 15px;
}

.site-header__mobile .topInfoBar li {
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  padding: 5px 20px;
  color: #fff;
}

.site-header__mobile .topInfoBar li.icon-sync {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  flex: 0 0 20%;
  padding: 5px;
}

.site-header__mobile .topInfoBar {
  padding: 10px 0;
  background-color: #000;
}

.site-header__mobile .topInfoBar li:before {
  display: none;
}

.site-header__mobile {
  padding-top: 56px;
}

.site-header__mobile .topInfoBar li .label {
  text-align: center;
  width: 100%;
}
.site-header__mobile .topInfoBar li.icon-local_shipping .label {
  text-align: right;
}
.site-header__mobile .topInfoBar li.icon-local_phone .label {
  text-align: left;
}

.site-header__mobile-top .minisearch .minisearch__control--text {
  padding: 10px 50px;
}

.site-header__mobile-top .minisearch .mobile-search-icon {
  display: block;
  background: url("/media/wysiwyg/Mollini/icons/search-icon.png") no-repeat;
  height: 22px;
  width: 22px;
  background-size: contain;
  position: absolute;
  top: 40px;
  left: 20px;
}

#search-visible-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

.search-visible-blur {
  filter: blur(2px);
}

.site-header__mobile-top .minisearch .search-close {
  right: 30px;
}

.search-close-button:before {
  display: none;
}

.search-close-button {
  content: "";
  font-family: initial;
  background: url("/media/wysiwyg/Mollini/icons/close-icon.png") no-repeat;
  background-size: contain;
  height: 17px;
  width: 17px;
}

.site-header__mobile-top .site-header__logo-container {
  position: absolute;
  top: 17px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: initial;
  max-width: 100px;
}

.site-header__logo-container img {
  max-height: 40px;
  width: auto;
  margin: 0 auto;
}

.topInfoBarContainer .topInfoBar a {
  letter-spacing: 0.5px;
}

.navigation-bar-scrolled {
  border-top: 1px solid #8e8e8e;
  border-bottom: 1px solid #8e8e8e;
}

.navigation-bar-scrolled .site-header__logo-container {
  position: initial;
}

.site-header__mobile-top .minisearch .minisearch__control--text::placeholder {
  color: #333;
  letter-spacing: 1px;
}

.mobile-navigation-container {
  border-right: 0;
}

@media (max-width: 960px) {
  .customer-welcome .customer-name,
  .authorization-link a[href*="login"] {
    display: block;
    background: url("/media/wysiwyg/Mollini/icons/account-icon.png") no-repeat;
    height: 22px;
    background-size: contain;
    margin-left: 15px;
  }
  .mobile-navigation-container .authorization-link a span {
    margin-left: 10px;
  }
  #js-aligent_fredhopper-autocomplete-results {
    top: 114px !important;
  }
  .site-header__logo-container img {
    max-height: 32px;
  }
}

@media (min-width: 960px) {
  .navigation-bar__group--account li.authorization-link a span {
    display: none;
  }
}

@media (max-width: 399px) {
  .site-header__logo-container img {
    max-height: 24px;
  }
  .site-header__mobile-top .site-header__logo-container {
    top: 22px;
  }
}
