.product-list__item .product-tile .badge-container .badge {
  border: 1px solid $color-sale;
  background-color: initial;
  color: $color-sale;
  font-size: 12px;
}

/* MMI-986 */
@media (max-width: 960px) {
  .product-list__item .product-tile .badge-container {
    position: absolute;
    left: 4px !important;
  }
  .product-list__item .product-tile .badge-container .badge {
    text-align: center;
  }
}

.product-list__item .product-tile .badge-container {
  left: 20px;
  top: 20px;
  right: auto !important;
}
